import React from 'react'
import styled from 'styled-components'

// Elements
import TitleDefault from 'components/elements/TitleDefault'

// Components
import Image from 'components/shared/ImageGatsby'
import ButtonDefault from 'components/elements/ButtonDefault'

const StyledButtonDefault = styled(ButtonDefault)`
  & > a {
    background-color: transparent;
    border: 1px solid ${(props) => props.theme.color.text.main};
    color: ${(props) => props.theme.color.text.main};
    padding: 17px 15px;

    &:hover {
      color: ${(props) => props.theme.color.text.main};
    }
  }
`

const StyledImage = styled(Image)`
  max-height: 300px;
`

interface ImagesWithLinkProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_ImagesWithLink
  location?: any
}

const ImagesWithLink: React.FC<ImagesWithLinkProps> = ({ fields }) => (
  <section className="my-5">
    <div className="container">
      <TitleDefault>{fields.description}</TitleDefault>
      <div className="row">
        {fields?.imageWithLink?.map((edge, index: number) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="col-md-6 mb-5" key={index}>
            <StyledImage image={edge?.image} alt="Lofthus" loading="lazy" />
            <StyledButtonDefault
              to={edge?.link?.url || ''}
              className="my-4 text-center"
            >
              {edge?.link?.title}
            </StyledButtonDefault>
          </div>
        ))}
      </div>
    </div>
  </section>
)

export default ImagesWithLink
